import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  LIST_NEWS,
  UPDATE_NEWSITEM,
  SET_NEWSITEM,
  SET_NEWS,
  SAVE_NEWSITEM,
  REMOVE_NEWSITEM,
} from "../actions/news/types";

const initialState = {
  isFetching: false,
  news: [],
  newsDetail: {},
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NEWS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        news: action.news || state.news,
      };
    case REMOVE_NEWSITEM:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_NEWSITEM:
      return {
        ...state,
        newsDetail: {
          ...state.newsDetail,
          [action.field]: action.value,
          longDescription:
            action.field === "longDescriptionState"
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.newsDetail.longDescription,
        },
      };
    case SET_NEWSITEM:
      return {
        ...state,
        isFetching: false,
        newsDetail: action.newsDetail,
      };
    case SET_NEWS:
      return {
        ...state,
        isFetching: false,
        news: action.news,
      };
    case SAVE_NEWSITEM:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default news;
