import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../../components/Loader';
import HashedRoute from '../../HashedRoute';
import CampsData from './CampsData';
import CampsDataDetail from './CampsDataDetail';
import Camps from './Camps';
import CampDetail from './CampDetail';
import ProductFeatures from './ProductFeatures';
import ProductFeatureDetail from './ProductFeatureDetail';
import Newsletters from './Newsletters';

const Users = lazy(() => import('./Users'));
const UsersDetail = lazy(() => import('./UsersDetail'));
const Content = lazy(() => import('./Content'));
const ContentDetail = lazy(() => import('./ContentDetail'));
const Contentgroups = lazy(() => import('./ContentGroups'));
const ContentgroupsDetail = lazy(() => import('./ContentGroupsDetail'));
const Rentals = lazy(() => import('./Rentals'));
const RentalDetail = lazy(() => import('./RentalDetail'));
const Registrations = lazy(() => import('./Registrations'));
const RegistrationDetail = lazy(() => import('./RegistrationDetail'));
const RentalCategories = lazy(() => import('./RentalCategories'));
const RentalCategoryDetail = lazy(() => import('./RentalCategoryDetail'));
const RentalReservations = lazy(() => import('./RentalReservations'));
const RentalReservationDetail = lazy(() => import('./RentalReservationDetail'));
const Staff = lazy(() => import('./Staff'));
const StaffDetail = lazy(() => import('./StaffDetail'));
const routes = [
  {
    path: '/users',
    component: Users,
    title: 'Gebruikers',
  },
  {
    path: '/users/new',
    component: UsersDetail,
    title: 'Nieuwe Gebruiker',
  },
  {
    path: '/users/detail/:id',
    component: UsersDetail,
    title: 'Gebruiker Detail',
  },
  {
    path: '/staff',
    component: Staff,
    title: 'Personeel',
  },
  {
    path: '/staff/new',
    component: StaffDetail,
    title: 'Nieuw Personeelslid',
  },
  {
    path: '/staff/detail/:id',
    component: StaffDetail,
    title: 'Personeel Detail',
  },
  {
    path: '/campfeatures',
    component: ProductFeatures,
    title: 'Kamp Attributen',
  },
  {
    path: '/campfeatures/new',
    component: ProductFeatureDetail,
    title: 'Nieuw Attribuut',
  },
  {
    path: '/campfeatures/detail/:id',
    component: ProductFeatureDetail,
    title: 'Attribuut Detail',
  },
  {
    path: '/camps',
    component: Camps,
    title: 'Kampen',
  },
  {
    path: '/campsdata',
    component: CampsData,
    title: 'Kamp Data',
  },
  {
    path: '/campsdata/new',
    component: CampsDataDetail,
    title: 'Nieuw Kamp Data',
  },
  {
    path: '/campsdata/detail/:id',
    component: CampsDataDetail,
    title: 'Kamp Data Detail',
  },
  {
    path: '/camps/new',
    component: CampDetail,
    title: 'Nieuw Kamp',
  },
  {
    path: '/camps/detail/:id',
    component: CampDetail,
    title: 'Kamp Detail',
  },
  {
    path: '/rentalcategories',
    component: RentalCategories,
    title: 'Verhuur categorieën',
  },
  {
    path: '/rentalcategories/new',
    component: RentalCategoryDetail,
    title: 'Nieuwe categorie',
  },
  {
    path: '/rentalcategories/detail/:id',
    component: RentalCategoryDetail,
    title: 'Categorie Detail',
  },
  {
    path: '/rentals',
    component: Rentals,
    title: 'Verhuurmaterialen',
  },
  {
    path: '/rentals/new',
    component: RentalDetail,
    title: 'Nieuwe Verhuur',
  },
  {
    path: '/rentals/detail/:id',
    component: RentalDetail,
    title: 'Verhuur Detail',
  },
  {
    path: '/rentalreservations',
    component: RentalReservations,
    title: 'Reservaties',
  },
  {
    path: '/rentalreservations/new',
    component: RentalReservationDetail,
    title: 'Nieuwe Reservatie',
  },
  {
    path: '/rentalreservations/detail/:id',
    component: RentalReservationDetail,
    title: 'Reservatie Detail',
  },
  {
    path: '/content',
    component: Content,
    title: 'Content',
  },
  {
    path: '/content/new',
    component: ContentDetail,
    title: 'Nieuwe Content',
  },
  {
    path: '/content/detail/:id',
    component: ContentDetail,
    title: 'Content Detail',
  },
  {
    path: '/contentgroups',
    component: Contentgroups,
    title: 'Contentgroepen',
  },
  {
    path: '/contentgroups/new',
    component: ContentgroupsDetail,
    title: 'Nieuwe Contentgroep',
  },
  {
    path: '/contentgroups/detail/:id',
    component: ContentgroupsDetail,
    title: 'Contentgroep Detail',
  },
  {
    path: '/newsletters',
    component: Newsletters,
    title: 'Inschrijvingen Nieuwsbroef',
  },
  {
    path: '/registrations',
    component: Registrations,
    title: 'Inschrijvingen',
  },
  {
    path: '/registrations/detail/:id',
    component: RegistrationDetail,
    title: 'Detail Inschrijving',
  },
];

const Router = (props) => {
  const createRoute = (path, Component, index) => {
    return (
      <Route
        exact
        key={index}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((item, i) => {
          return createRoute(item.path, item.component, i);
        })}
        <HashedRoute path="/" component={Users} />
      </Switch>
    </Suspense>
  );
};

export default Router;

export const getRouteTitle = (path) => {
  for (var i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      path.indexOf(route.pathForTitle ? route.pathForTitle : route.path) === 0
    ) {
      return route.title;
    }
  }
  return '';
};
