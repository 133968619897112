import fetchAxios from "../../middlewares/axios";
import {
  LIST_FEATURES,
  LOAD_FEATURE,
  SET_FEATURES,
  SET_FEATURE,
  UPDATE_FEATURE,
  SAVE_FEATURE,
  REMOVE_FEATURE,
} from "./types";
import { ROOT_URL_PROTECTED } from "../../../config/axios";

export const listFeatures = () => {
  return (dispatch) => {
    dispatch({ type: LIST_FEATURES, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        "get",
        `${ROOT_URL_PROTECTED}/productfeatures/`,
        null,
        null,
        true,
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.productfeatures
          ) {
            dispatch({
              type: SET_FEATURES,
              productFeatures: response.data.productfeatures,
            });
            resolve(response);
          } else {
            reject("LOADERROR");
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_FEATURES });
          reject({ error: e });
        });
    });
  };
};

export const showFeature = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_FEATURE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        "get",
        `${ROOT_URL_PROTECTED}/productfeatures/detail/${id}`,
        null,
        null,
        true,
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.productfeature
          ) {
            dispatch({
              type: SET_FEATURE,
              featureDetail: response.data.productfeature,
            });
            resolve(response);
          } else {
            reject("LOADERROR");
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_FEATURE });
          reject({ error: e });
        });
    });
  };
};

export const saveFeature = () => {
  return (dispatch, getState) => {
    const { productFeatures } = getState();
    dispatch({ type: SAVE_FEATURE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        "post",
        `${ROOT_URL_PROTECTED}/productfeatures/update`,
        {
          feature: productFeatures.featureDetail,
        },
        null,
        true,
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.productfeature
          ) {
            dispatch({
              type: SET_FEATURE,
              featureDetail: response.data.productfeature,
            });
            resolve(response.data);
          } else {
            reject("SAVEERROR");
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_FEATURE });
          reject({ error: e });
        });
    });
  };
};

export const removeFeature = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_FEATURE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        "post",
        `${ROOT_URL_PROTECTED}/productfeatures/remove`,
        {
          id,
        },
        null,
        true,
      )
        .then((response) => {
          dispatch({
            type: REMOVE_FEATURE,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject("REMOVEERROR");
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_FEATURE });
          reject({ error: e });
        });
    });
  };
};

export const updateFeature = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE,
      field,
      value,
    });
  };
};

export const resetFeature = (feature) => {
  return (dispatch) => {
    dispatch({
      type: SET_FEATURE,
      featureDetail: feature || {},
    });
  };
};
