import React from "react";
import "./style.scss";

const FormBlock = ({ title, children }) => {
  return (
    <div className="formblock-root">
      <div className="formblock-root__title">
        <span>{title}</span>
      </div>
      <div className="formblock-root__content">{children}</div>
    </div>
  );
};

export default FormBlock;
