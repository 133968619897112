/**
 * Vul hier je eigen Amazon S3 gegevens in net zoal voor de front-end
 * Daarna zal je alle afbeelding in het CMS opnieuw moeten uploaden zodat ze niet meer op mijn Amazon omgeving komen maar op die van jullie.
 * Dit kan je makkelijk controleren door op de afbeelding te rechterklikken en de URL te checken, die moet verwijzen naar jullie s3 omgeving.
 */
import Logo from './assets/images/logo-thobe-events.png';
export const appVersion = '1.8';
export const AppName = 'ThoBe CMS';
export const mainColor = '#01b2c5';
export const awsAccessKey = 'AKIA6GBMFO6XH7VKQMCV'; //vervang met je eigen S3 access key
export const awsSecretKey = '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'; //vervang met je eigen S3 secret key
export const bucketName = 'thobee';
export const frontEndLanguages = [
  {
    iso: 'nl',
    language: 'Nederlands',
  },
];
export const logo = Logo;
