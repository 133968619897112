import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import {
  resetCampV2,
  saveCampV2,
  showCampV2,
  updateCampV2,
} from '../../../redux/actions/camps';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import { listFeatures } from '../../../redux/actions/productfeatures';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchSafe } from '../../../utils/text';

class CampDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newCategory: {},
    newFeature: {},
  };

  componentDidMount = () => {
    this.props.doListProductFeatures();
    if (this.state.id) {
      this.props.doShowCampV2(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen kamp');
        this.props.history.push('/camps');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.campCopy
      ) {
        let newCopy = { ...this.props.location.state.campCopy };
        newCopy.copyFromCamp = JSON.parse(JSON.stringify(newCopy._id));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetCampV2(newCopy);
      } else {
        this.props.doResetCampV2();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp(
      mobile ? 'bannerImgMobile' : 'bannerImg',
      files[0].location
    );
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw verhuur niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('video', files[0].location);
  };

  onMainUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('mainImg', files[0].location);
  };

  onPDFUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('registrationPDF', files[0].location);
  };

  onCarrouselImgsComplete = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    const imgs = files.map((file) => file.location);
    this.props.doUpdateCamp('carrouselImgs', imgs);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateCamp('longDescriptionState', editorState);
  };

  saveCamp = () => {
    const { _id, title, info, url } = this.props.campDetail;
    if (
      !title ||
      title.length < 2 ||
      !info ||
      info.length < 2 ||
      !url ||
      url.length < 3
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld. Controleer de verplichte velden met *.',
        'error'
      );
    }
    this.props
      .doSaveCampV2()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Kamp toegevoegd', 'success');
          return this.props.history.push(`/camps/detail/${result.camp._id}`);
        } else {
          return this.props.doSnack('Kamp bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'camps' + '_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  updateNewCategory = (val) => {
    this.setState({
      ...this.state,
      newCategory: {
        category: val,
      },
    });
  };

  addNewCategory = (e) => {
    e.preventDefault();
    const { campCategories } = this.props;
    const cat = this.state.newCategory.category;
    for (let c = 0; c < campCategories.length; c++) {
      if (campCategories[c].category === cat) {
        return swal('Probleem', 'Deze category bestaat al', 'error');
      }
    }
    this.props
      .doAddCampCategory(cat)
      .then(() => {
        this.setState(
          {
            newCategory: {},
          },
          () => {
            this.props.doSnack('Categorie toegevoegd', 'success');
            this.props.doListCampCategories();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  changeFeature = (feature) => {
    let theFeature;
    for (let f = 0; f < this.props.productFeatures.length; f++) {
      if (this.props.productFeatures[f].title === feature) {
        theFeature = this.props.productFeatures[f];
        break;
      }
    }
    this.setState({
      newFeature: {
        feature,
        featureValues: theFeature.values,
      },
    });
  };

  changeFeatureValue = (selectedFeatureValue) => {
    this.setState({
      newFeature: {
        ...this.state.newFeature,
        selectedFeatureValue,
      },
    });
  };

  addNewFeature = () => {
    const { features } = this.props.campDetail;
    const { newFeature } = this.state;
    let newValues = [];
    if (features && Object.keys(features).length) {
      const featureKeys = Object.keys(features);
      for (let f = 0; f < featureKeys.length; f++) {
        if (featureKeys[f] === newFeature.feature) {
          const currentValues = features[newFeature.feature];
          if (typeof currentValues === 'string') {
            if (currentValues === newFeature.selectedFeatureValue) {
              return this.props.doSnack('Deze waarde werd al toegevoegd');
            }
            newValues.push(currentValues, newFeature.selectedFeatureValue);
          } else {
            if (currentValues.indexOf(newFeature.selectedFeatureValue) > -1) {
              return this.props.doSnack('Deze waarde werd al toegevoegd');
            }
            newValues = [...currentValues];
            newValues.push(newFeature.selectedFeatureValue);
          }
        }
      }
    }
    let newFeatures = features ? { ...features } : {};
    newFeatures[newFeature.feature] = newValues.length
      ? newValues
      : [newFeature.selectedFeatureValue];
    this.props.doUpdateCamp('features', newFeatures);
    this.setState({
      newFeature: {},
    });
    this.props.doSnack(
      'Attribuut toegevoegd. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  removeFeature = (feature) => {
    let { features } = this.props.campDetail;
    delete features[feature];
    this.props.doUpdateCamp('features', features);
    this.props.doSnack(
      'Attribuut verwijderd. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  setURL = () => {
    const { campDetail } = this.props;
    if (!campDetail.url || !campDetail.url.length) {
      this.props.doUpdateCamp(
        'url',
        searchSafe(campDetail.title, false, true).toLowerCase()
      );
    }
  };

  render() {
    const { campDetail, productFeatures } = this.props;
    const { newFeature } = this.state;

    const longDescriptionState =
      campDetail && campDetail.longDescriptionState
        ? campDetail.longDescriptionState
        : campDetail.longDescription
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(campDetail.longDescription)
            )
          )
        : EditorState.createEmpty();

    return (
      <div>
        <div className="campdetail-root">
          <div className="campdetail-root__header">
            <button className="btn btn-primary" onClick={() => this.saveCamp()}>
              Sla gegevens op
            </button>
          </div>
          <div className="campdetail-root__form">
            <FormBlock title={`Details`}>
              <form>
                <CustomForm>
                  <label>Titel *</label>
                  <input
                    id="title"
                    name="title"
                    value={campDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('title', e.target.value)
                    }
                    onBlur={() => this.setURL()}
                    disabled={campDetail._id}
                  />
                  <label htmlFor="info">Korte info</label>
                  <textarea
                    id="info"
                    name="info"
                    value={campDetail.info || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('info', e.target.value)
                    }
                    rows={4}
                  />
                  <label>URL * (geen spaties of speciale tekens)</label>
                  <input
                    id="url"
                    name="url"
                    value={campDetail.url || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('url', e.target.value)
                    }
                    disabled={campDetail._id}
                  />
                  <label>
                    Tags (meerdere tags scheiden door een komma als in vb: kamp,
                    kind, bounce, springkasteel)
                  </label>
                  <textarea
                    id="searchTags"
                    name="searchTags"
                    value={campDetail.tags || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('tags', e.target.value)
                    }
                    rows="4"
                  />
                </CustomForm>
              </form>
            </FormBlock>
            {campDetail._id && (
              <FormBlock title="Kamp gegevens">
                <form>
                  <CustomForm>
                    <label htmlFor="unavailable">
                      <input
                        id="unavailable"
                        name="unavailable"
                        type="checkbox"
                        checked={campDetail.unavailable ? 'checked' : ''}
                        onChange={(e) =>
                          this.props.doUpdateCamp(
                            'unavailable',
                            !campDetail.unavailable
                          )
                        }
                        style={{ marginRight: 10 }}
                      />
                      Verborgen
                    </label>
                    {false && (
                      <label htmlFor="isFavorite">
                        <input
                          id="isFavorite"
                          name="isFavorite"
                          type="checkbox"
                          checked={campDetail.isFavorite ? 'checked' : ''}
                          onChange={(e) =>
                            this.props.doUpdateCamp(
                              'isFavorite',
                              !campDetail.isFavorite
                            )
                          }
                          style={{ marginRight: 10 }}
                        />
                        Favoriet
                      </label>
                    )}
                    <label htmlFor="name">Lange omschrijving</label>
                    <Editor
                      editorState={longDescriptionState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="wysiwygeditor"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: this.uploadImageCallBack,
                          alt: { present: true, mandatory: false },
                          previewImage: true,
                          inputAccept: 'image/jpeg,image/jpg,image/png',
                        },
                      }}
                    />
                    {false && (
                      <>
                        <label>
                          Youtube Video ID (wat achter '/watch?v=' staat vb.
                          44KNdTRyAHo)
                        </label>
                        {campDetail.youtubeID && (
                          <iframe
                            width="100%"
                            height="320"
                            src={`https://www.youtube.com/embed/${campDetail.youtubeID}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        )}
                        <input
                          id="youtubeID"
                          name="youtubeID"
                          value={campDetail.youtubeID || ''}
                          onChange={(e) =>
                            this.props.doUpdateCamp('youtubeID', e.target.value)
                          }
                        />
                      </>
                    )}
                    <label htmlFor="bannerTitle">Banner Titel</label>
                    <textarea
                      id="bannerTitle"
                      name="bannerTitle"
                      value={campDetail.bannerTitle || ''}
                      onChange={(e) =>
                        this.props.doUpdateCamp('bannerTitle', e.target.value)
                      }
                      rows={3}
                    />
                    <label>
                      Hoofdafbeelding voor overzicht en detail (1200x800 max
                      250kb)
                    </label>
                    {campDetail.mainImg && (
                      <img src={campDetail.mainImg} width="320px" alt="main" />
                    )}
                    <FileUpload
                      onUploadComplete={(files) => this.onMainUpload(files)}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg"
                      prefix="camps"
                    />
                    {false && (
                      <>
                        <label>Banner afbeelding detailpagina</label>
                        {campDetail.bannerImg && (
                          <div>
                            <img
                              src={campDetail.bannerImg}
                              width="auto"
                              style={{ maxHeight: 320, marginBottom: 6 }}
                              alt="head"
                            />
                          </div>
                        )}
                        <FileUpload
                          onUploadComplete={this.onHeaderUpload}
                          onError={() =>
                            this.props.doSnack('Probleem met upload', 'error')
                          }
                          bucketName={bucketName}
                          awsAccessKey={awsAccessKey}
                          awsSecretKey={awsSecretKey}
                          multiple={false}
                          accept="image/png, image/jpeg, .svg, .webp"
                          prefix="camps"
                        />

                        <label>Banner afbeelding detailpagina (MOBILE)</label>
                        {campDetail.bannerImgMobile && (
                          <div>
                            <img
                              src={campDetail.bannerImgMobile}
                              alt="head"
                              width="auto"
                              style={{
                                maxHeight: 320,
                                marginBottom: 6,
                              }}
                            />
                          </div>
                        )}
                        <FileUpload
                          onUploadComplete={(files) =>
                            this.onHeaderUpload(files, true)
                          }
                          onError={() =>
                            this.props.doSnack('Probleem met upload', 'error')
                          }
                          bucketName={bucketName}
                          awsAccessKey={awsAccessKey}
                          awsSecretKey={awsSecretKey}
                          multiple={false}
                          accept="image/png, image/jpeg, .svg, .webp"
                          prefix="camps"
                        />
                        <label>
                          Banner video detailpagina (1200 x 800 max 250kb)
                        </label>
                        {campDetail.bannerVideo && (
                          <video width="100%" height="300" controls>
                            <source
                              src={campDetail.bannerVideo}
                              type="video/mp4"
                            />
                          </video>
                        )}
                        <FileUpload
                          onUploadComplete={this.onVideoUpload}
                          onError={() =>
                            this.props.doSnack('Probleem met upload', 'error')
                          }
                          bucketName={bucketName}
                          awsAccessKey={awsAccessKey}
                          awsSecretKey={awsSecretKey}
                          multiple={false}
                          accept=".mp4"
                          prefix="camps"
                          maxSizeKB={2000}
                        />
                      </>
                    )}
                    <label>
                      Afbeeldingen voor carrousel (1200 x 1200 max 250kb)
                    </label>
                    {campDetail.carrouselImgs &&
                      campDetail.carrouselImgs.length && (
                        <div className="campdetail-root__form__imgbox">
                          {campDetail.carrouselImgs.map((img, i) => (
                            <img
                              key={i}
                              src={img}
                              width="120px"
                              height="120px"
                              style={{ margin: 10 }}
                              alt="color"
                            />
                          ))}
                        </div>
                      )}
                    <FileUpload
                      onUploadComplete={(files) =>
                        this.onCarrouselImgsComplete(files, true)
                      }
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={true}
                      accept="image/png, image/jpeg"
                      prefix="camps"
                    />
                  </CustomForm>
                </form>
              </FormBlock>
            )}
            {campDetail._id && (
              <FormBlock title="Attributen">
                <CustomForm>
                  <label>Kamp attributen</label>
                  <table
                    border="1"
                    width="100%"
                    className="campdetail-root__form__table"
                  >
                    <tbody>
                      {(campDetail.features &&
                        Object.keys(campDetail.features).length &&
                        Object.keys(campDetail.features).map((feature, f) => (
                          <tr key={f}>
                            <td>{feature}</td>
                            <td>
                              {typeof campDetail.features[feature] === 'string'
                                ? campDetail.features[feature]
                                : campDetail.features[feature].join()}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <button
                                onClick={() => this.removeFeature(feature)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))) ||
                        null}
                    </tbody>
                  </table>
                  <label htmlFor="features">Beschikbare attributen</label>
                  <select
                    name="features"
                    value={newFeature.feature || ''}
                    onChange={(e) => this.changeFeature(e.currentTarget.value)}
                  >
                    <option value="">Kies attribuut</option>
                    {productFeatures.map((feature, f) => (
                      <option key={f} value={feature.title}>
                        {feature.title}
                      </option>
                    ))}
                  </select>
                  {newFeature.feature && newFeature.featureValues && (
                    <select
                      name="featureValues"
                      value={newFeature.selectedFeatureValue}
                      onChange={(e) =>
                        this.changeFeatureValue(e.currentTarget.value)
                      }
                    >
                      <option value="">Selecteer attribuut waarde</option>
                      {newFeature.featureValues.map((featureVal, fa) => (
                        <option key={fa} value={featureVal.value}>
                          {featureVal.value}
                        </option>
                      ))}
                    </select>
                  )}
                  {newFeature.feature && newFeature.selectedFeatureValue && (
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.addNewFeature()}
                    >
                      Voeg attribuut toe
                    </button>
                  )}
                </CustomForm>
              </FormBlock>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campDetail: state.camps.campDetailv2,
    productFeatures: state.productFeatures.productFeatures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListProductFeatures: () => {
      return dispatch(listFeatures());
    },
    doSaveCampV2: () => {
      return dispatch(saveCampV2());
    },
    doResetCampV2: (copy) => {
      dispatch(resetCampV2(copy));
    },
    doShowCampV2: (id) => {
      return dispatch(showCampV2(id));
    },
    doUpdateCamp: (field, val) => {
      dispatch(updateCampV2(field, val));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampDetail);
