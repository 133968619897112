import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  LIST_RENTALS,
  UPDATE_RENTAL,
  SET_RENTAL,
  SET_RENTALS,
  SAVE_RENTAL,
  REMOVE_RENTAL,
  LIST_RENTAL_CATEGORIES,
  SET_RENTAL_CATEGORIES,
  SET_RENTALCATEGORY,
  REMOVE_RENTALCATEGORY,
  UPDATE_RENTALCATEGORY,
  SAVE_RENTALCATEGORY,
  LIST_RESERVATIONS,
  REMOVE_RESERVATION,
  UPDATE_RESERVATION,
  SET_RESERVATION,
  SET_RESERVATIONS,
  SAVE_RESERVATION,
  LIST_ACTIVE_RENTALS,
  SET_ACTIVE_RENTALS,
} from '../actions/rentals/types';

const initialState = {
  isFetching: false,
  rentals: [],
  rentalDetail: {},
  rentalCategories: [],
  rentalCategoryDetail: {},
  reservations: [],
  reservationDetail: {},
  activeRentals: [],
};

const rentals = (state = initialState, action) => {
  switch (action.type) {
    case LIST_RENTALS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        rentals: action.rentals || state.rentals,
      };
    case LIST_ACTIVE_RENTALS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        activeRentals: action.activeRentals || state.activeRentals,
      };
    case LIST_RESERVATIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        reservations: action.reservations || state.reservations,
      };
    case LIST_RENTAL_CATEGORIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_RENTAL_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        rentalCategories: action.rentalCategories,
      };
    case SET_RENTALCATEGORY:
      return {
        ...state,
        isFetching: false,
        rentalCategoryDetail: action.rentalCategoryDetail,
      };
    case REMOVE_RENTAL:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_RESERVATION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_RENTALCATEGORY:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_RENTAL:
      return {
        ...state,
        rentalDetail: {
          ...state.rentalDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.rentalDetail.longDescription,
        },
      };
    case UPDATE_RESERVATION:
      return {
        ...state,
        reservationDetail: {
          ...state.reservationDetail,
          [action.field]: action.value,
        },
      };
    case UPDATE_RENTALCATEGORY:
      return {
        ...state,
        rentalCategoryDetail: {
          ...state.rentalCategoryDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.rentalCategoryDetail.longDescription,
        },
      };
    case SET_RENTAL:
      return {
        ...state,
        isFetching: false,
        rentalDetail: action.rentalDetail,
      };
    case SET_RESERVATION:
      return {
        ...state,
        isFetching: false,
        reservationDetail: action.reservationDetail,
      };
    case SET_RENTALS:
      return {
        ...state,
        isFetching: false,
        rentals: action.rentals,
      };
    case SET_RESERVATIONS:
      return {
        ...state,
        isFetching: false,
        reservations: action.reservations,
      };
    case SET_ACTIVE_RENTALS:
      return {
        ...state,
        isFetching: false,
        activeRentals: action.activeRentals,
      };
    case SAVE_RENTAL:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_RESERVATION:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_RENTALCATEGORY:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default rentals;
