export const getNestedPropsFromString = (string, obj, flat) => {
  var value = undefined;
  var level = 0;
  if (
    string &&
    string.length &&
    obj &&
    Object.getOwnPropertyNames(obj).length
  ) {
    string.split('.').forEach((dataEl, i) => {
      if (i === level) {
        if (i === 0) {
          if (obj[dataEl]) {
            value = obj[dataEl];
            level++;
          }
        } else {
          if (value && value[dataEl]) {
            value = value[dataEl];
            level++;
          }
        }
      }
    });
  }
  if (
    flat &&
    typeof value !== 'string' &&
    typeof value !== 'number' &&
    typeof value !== 'boolean'
  ) {
    value = null;
  }
  return value;
};

export const removePropFromObject = (obj, prop) => {
  const { [prop]: remove, ...rest } = obj;
  return rest;
};
