import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable, {
  tableFilter,
  tableSort,
} from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import './style.scss';
import {
  listCampsData,
  removeCampData,
  setCampsActiveById,
} from '../../../redux/actions/camps';
import { removePropFromObject } from '../../../utils/object';

const fields = [
  {
    title: 'Kamp',
    prop: 'camp.title',
  },
  {
    title: 'Verborgen',
    prop: 'unavailable',
    boolAsCheck: true,
  },
  {
    title: 'Van',
    prop: 'dateFromUTC',
    asDateTime: true,
    dateFormat: 'dd/MM/yyyy',
  },
  {
    title: 'Tot',
    prop: 'dateToUTC',
    asDateTime: true,
    dateFormat: 'dd/MM/yyyy',
  },
  {
    title: 'Geb. van',
    prop: 'ageFrom',
  },
  {
    title: 'Geb. tot',
    prop: 'ageTo',
  },
  {
    title: 'Extern',
    prop: 'externalRegistration',
    boolAsCheck: true,
  },
  {
    title: 'Max. Inschr.',
    prop: 'maxRegistrations',
  },
  {
    title: 'Inschrijvingen',
    prop: 'succesfulRegistrations',
  },
];

const tableActions = ['edit', 'delete'];

const defaultFilter = { 'unavailable---true': true };

class Camps extends Component {
  state = {
    itemsSorted: undefined,
    activeFilters: defaultFilter,
    activeSort: 'dateFromUTC',
    selectedItems: [],
  };

  componentDidMount = () => {
    this.props.doListCampsData();
  };

  editCampData = (campData) => {
    return this.props.history.push(`/campsdata/detail/${campData._id}`);
  };

  deleteCampData = (campData) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveCampData(campData._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListCampsData();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  setSelectedItem = (itemID, selected) => {
    const { selectedItems } = this.state;
    let newSelectedItems = [...selectedItems];
    if (selected) {
      newSelectedItems.push(itemID);
    } else {
      const ind = selectedItems.indexOf(itemID);
      if (ind > -1) {
        newSelectedItems.splice(ind, 1);
      }
    }
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  activateSelectedItems = (items, selectedItems) => {
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (selectedItems.indexOf(items[i]._id) > -1) {
          items[i].selected = true;
        } else {
          items[i].selected = false;
        }
      }
    }
    return items;
  };

  setSelectedCampsDataActive = () => {
    this.props
      .doSetCampsDataActiveById(this.state.selectedItems)
      .then(() => {
        swal('Gelukt', 'Kampdata actief gezet', 'success');
        this.props.doListCampsData();
      })
      .catch((e) => {
        console.log(e);
        swal('Probleem', 'Probleem bij actief zetten van kampdata', 'error');
      });
  };

  onTableAction = (id, action) => {
    if (action === 'downloadExcel') {
    }
  };

  render() {
    const { campsdata } = this.props;
    const { activeFilters, activeSort, selectedItems } = this.state;
    let campsDataFiltered = Object.keys(activeFilters).length
      ? tableFilter(campsdata, activeFilters)
      : campsdata;
    let campsDataSorted = activeSort
      ? tableSort(activeSort, campsDataFiltered)
      : campsDataFiltered;
    campsDataSorted = this.activateSelectedItems(
      campsDataSorted,
      selectedItems
    );
    return (
      <div>
        {(selectedItems && selectedItems.length && (
          <div style={{ width: '100%', padding: 16 }}>
            <button
              className="btn btn-warning"
              onClick={() => this.setSelectedCampsDataActive()}
            >
              {selectedItems.length} kamp(en) actief zetten
            </button>
          </div>
        )) ||
          null}
        <StyledTable
          title="Kampdata"
          fields={fields}
          bodyItems={campsDataSorted || []}
          actions={tableActions}
          sortOptions={[
            { name: 'Datum Van', sort: 'dateFromUTC' },
            { name: 'Kamptitel', sort: 'camp.title' },
            { name: 'Extern', sort: 'externalRegistration' },
          ]}
          onSort={(sortTo) => this.setState({ activeSort: sortTo })}
          filter={[
            {
              name: 'Toon verborgen',
              prop: 'unavailable',
              value: true,
            },
          ]}
          onFilter={(prop, propValue, value) =>
            value
              ? this.setState({
                  activeFilters: removePropFromObject(
                    this.state.activeFilters,
                    `${prop}---${propValue}`
                  ),
                })
              : this.setState({
                  activeFilters: {
                    ...this.state.activeFilters,
                    [`${prop}---${propValue}`]: propValue,
                  },
                })
          }
          filtersActive={activeFilters}
          addNew="Nieuw kampdata"
          addNewLink="/campsdata/new"
          onEdit={this.editCampData}
          onDelete={this.deleteCampData}
          selectable={true}
          changeSelectedItems={this.setSelectedItem}
          tableAction={this.onTableAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campsdata: state.camps.campsdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListCampsData: () => {
      dispatch(listCampsData());
    },
    doSetCampsDataActiveById: (campids) => {
      return dispatch(setCampsActiveById(campids));
    },
    doRemoveCampData: (id) => {
      return dispatch(removeCampData(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Camps);
