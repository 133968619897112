import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import users from './users';
import content from './content';
import camps from './camps';
import rentals from './rentals';
import categories from './categories';
import newsletters from './newsletters';
import registrations from './registrations';
import news from './news';
import productFeatures from './productFeatures';
import staff from './staff';
const appReducer = combineReducers({
  app,
  auth,
  users,
  content,
  camps,
  rentals,
  categories,
  newsletters,
  registrations,
  news,
  productFeatures,
  staff,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
