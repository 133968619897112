import React, { Component } from 'react';
import { uploadFile } from './S3Upload';
import ScaleLoader from 'react-spinners/ScaleLoader';
import swal from 'sweetalert';
import './style.scss';
import AWS from 'aws-sdk';

class FileUpload extends Component {
  state = {
    files: undefined,
    uploading: false,
    currentFile: 0,
    totalFiles: 0,
  };

  fileChange = (e) => {
    e.preventDefault();
    console.log('the file has changed');
    let files = e.target.files;

    for (let f = 0; f < files.length; f++) {
      const file = files[f];
      const clearName = file.name.replace(/[0-9a-z]/gi, '');
      if (clearName?.length !== 1 || clearName?.charAt(0) !== '.') {
        this.clearFileUpload();
        document.getElementById('imageuploader').value = '';
        return swal(
          'Probleem bestand',
          'De bestandsnaam ' +
            file.name +
            ' is niet toegelaten. Enkel alfanumerieke tekens zonder spaties zijn toegelaten in de bestandsnaam.',
          'error'
        );
      }
      if (this.props.maxSizeKB && parseInt(this.props.maxSizeKB)) {
        const size = (files[f].size / 1024).toFixed(4);
        if (size > this.props.maxSizeKB) {
          document.getElementById('imageuploader').value = '';
          return swal(
            'Probleem bestand',
            'Eén van de bestanden is te groot. De maximum bestandsgrootte is ' +
              this.props.maxSizeKB,
            'error'
          );
        }
      }
    }

    this.setState({
      files,
    });
  };

  clearFileUpload = () => {
    let fileUploadFields = document.getElementsByClassName('fileupload');
    for (let i = 0; i < fileUploadFields.length; i++) {
      fileUploadFields[i].value = '';
    }
  };

  uploadFiles = (e) => {
    e.preventDefault();
    const { files } = this.state;
    if (!files) return;
    const prefix = this.props.prefix || 'cmsupload';
    const config = {
      bucketName: this.props.bucketName,
      region: 'eu-central-1',
      accessKeyId: this.props.awsAccessKey,
      secretAccessKey: this.props.awsSecretKey,
      dirName: prefix + '_' + Date.now(),
    };

    AWS.config.update({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: 'eu-central-1',
      s3_signature_version: 'v4',
    });

    let AWSBucket = new AWS.S3({
      params: { Bucket: config.bucketName },
    });

    let fileIndex = 0;
    let uploadedFiles = [];
    const uploadAFile = () => {
      const currentFile = files[fileIndex];

      this.setState(
        {
          currentFile: fileIndex + 1,
        },
        () => {
          setTimeout(() => {
            let extensions = currentFile.name.split('.');
            let originalName = currentFile.name;
            let newName =
              extensions.slice(0, extensions.length - 1).join('') + Date.now();
            let randomfile =
              Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111;
            newName = `${randomfile}_${Date.now()}.${
              extensions[extensions.length - 1]
            }`;
            if (this.props.keepOriginalName) {
              newName = originalName;
            }

            var params = {
              Key: newName,
              ContentType: currentFile?.type,
              Body: currentFile,
            };

            AWSBucket.upload(params, (err, uploadRes) => {
              if (err) {
                console.log(err);
                return this.props.onError();
              } else {
                if (uploadRes.Location) {
                  uploadedFiles.push({
                    name: files[fileIndex].name,
                    location: encodeURI(uploadRes.Location),
                  });
                  fileIndex++;
                  if (fileIndex < files.length) {
                    uploadAFile();
                  } else {
                    this.setState({
                      uploading: false,
                      files: undefined,
                    });
                    this.clearFileUpload();
                    document.getElementById('imageuploader').value = '';
                    return this.props.onUploadComplete(uploadedFiles);
                  }
                }
              }
            });
          }, 50);
        }
      );
    };
    this.setState(
      {
        uploading: true,
        currentFile: 0,
        totalFiles: files.length,
      },
      () => uploadAFile()
    );
  };

  render() {
    const { uploading, files, currentFile, totalFiles } = this.state;
    return (
      <div className="imageupload-root">
        <input
          id="imageuploader"
          type="file"
          onChange={(e) => this.fileChange(e)}
          accept={this.props.accept || '*'}
          multiple={this.props.multiple || false}
          className="fileupload imageupload-root__fileinput"
        />
        <div>
          <button
            disabled={!files}
            onClick={(e) => this.uploadFiles(e)}
            className="imageupload-root__btn"
          >
            {uploading
              ? `Bezig met uploaden ${currentFile} van ${totalFiles}`
              : 'Upload'}
          </button>
        </div>
        {uploading && (
          <ScaleLoader size={45} color={'#16326e'} loading={uploading} />
        )}
      </div>
    );
  }
}

export default FileUpload;
