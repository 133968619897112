export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const LIST_REGISTRATIONS = 'LIST_REGISTRATIONS';
export const REMOVE_REGISTRATION = 'REMOVE_REGISTRATION';
export const SET_CONTEST = 'SET_CONTEST';
export const LIST_CONTEST = 'LIST_CONTEST';
export const LIST_ACTIVE_CAMPS = 'LIST_ACTIVE_CAMPS';
export const SET_ACTIVE_CAMPS = 'SET_ACTIVE_CAMPS';
export const SWITCH_CAMP = 'SWITCH_CAMP';
export const LOAD_REGISTRATION = 'LOAD_REGISTRATION';
export const SET_REGISTRATION = 'SET_REGISTRATION';
export const SAVE_REGISTRATION = 'SAVE_REGISTRATION';
export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION';
