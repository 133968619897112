import {
  faUsers,
  faBox,
  faDollarSign,
  faGripLines,
  faLayerGroup,
  faStar,
  faBaseballBall,
  faClock,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

const NavigationLinks = [
  {
    icon: faUsers,
    color: '#f1cc05',
    title: 'Gebruikers',
    link: '/users',
  },
  {
    icon: faStar,
    color: '#f17305',
    title: 'Kamp Attributen',
    link: '/campfeatures',
  },
  {
    icon: faBaseballBall,
    color: '#f17305',
    title: 'Kampen',
    link: '/camps',
  },
  {
    icon: faClock,
    color: '#f17305',
    title: 'Kamp Data',
    link: '/campsdata',
  },
  {
    icon: faDollarSign,
    color: '#f17305',
    title: 'Inschrijvingen',
    link: '/registrations',
  },
  {
    icon: faBox,
    color: '#05f17f',
    title: 'Verhuur Categorieën',
    link: '/rentalcategories',
  },
  {
    icon: faBox,
    color: '#05f17f',
    title: 'Verhuur',
    link: '/rentals',
  },
  {
    icon: faBox,
    color: '#05f17f',
    title: 'Reservaties',
    link: '/rentalreservations',
  },
  {
    icon: faUsers,
    color: '#e1133e',
    title: 'Personeel',
    link: '/staff',
  },
  {
    icon: faGripLines,
    color: '#e84ddb',
    title: 'Content',
    link: '/content',
  },
  {
    icon: faLayerGroup,
    color: '#e84ddb',
    title: 'Contentgroepen',
    link: '/contentgroups',
  },
  {
    icon: faUsersCog,
    color: '#dd7e5e',
    title: 'Nieuwsbrief',
    link: '/newsletters',
  },
];

export default NavigationLinks;
