import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import {
  listFeatures,
  resetFeature,
  saveFeature,
  showFeature,
  updateFeature,
} from '../../../redux/actions/productfeatures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

class ProductFeatureDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newFeatureValue: {},
  };

  componentDidMount = () => {
    if (this.state.id) {
      this.props.doShowFeature(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen attribuut');
        this.props.history.push('/campfeatures');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.featureCopy
      ) {
        let newCopy = { ...this.props.location.state.featureCopy };
        newCopy.copyFromLanguage = JSON.parse(JSON.stringify(newCopy.language));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetFeature(newCopy);
      } else {
        this.props.doResetFeature();
      }
    }
  };

  saveFeature = () => {
    const { _id, title } = this.props.featureDetail;
    if (!title || title.length < 2) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld.',
        'error'
      );
    }
    this.props
      .doSaveFeature()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Feature toegevoegd', 'success');
          return this.props.history.push(
            `/campfeatures/detail/${result.productfeature._id}`
          );
        } else {
          return this.props.doSnack('Feature bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewLanguage = (e) => {
    e.preventDefault();
    this.props.history.push('/campfeatures/new', {
      featureCopy: { ...this.props.featureDetail },
    });
  };

  updateNewFeatureValue = (key, val) => {
    this.setState({
      ...this.state,
      newFeatureValue: {
        ...this.state.newFeatureValue,
        [key]: val,
      },
    });
  };

  editFeatureValue = (val) => {
    this.setState({
      newFeatureValue: val,
    });
  };

  removeFeatureValue = (index) => {
    let { values } = this.props.featureDetail;
    values.splice(index, 1);
    this.props.doUpdateFeature('values', values);
  };

  saveFeatureValue = (e) => {
    e.preventDefault();
    const { values } = this.props.featureDetail;
    const newFVal = this.state.newFeatureValue.key
      ? {
          ...this.state.newFeatureValue,
          value: this.state.newFeatureValue.value.trim(),
        }
      : {
          ...this.state.newFeatureValue,
          value: this.state.newFeatureValue.value.trim(),
          key: Math.random() * (999999 - 111111) + 111111,
        };

    let newValues = [];
    if (this.state.newFeatureValue.key) {
      for (var v = 0; v < values.length; v++) {
        if (values[v].key !== newFVal.key) {
          newValues.push(values[v]);
        } else {
          newValues.push(newFVal);
        }
      }
    } else {
      newValues =
        values && values.length ? values.concat([newFVal]) : [newFVal];
    }
    this.props.doUpdateFeature('values', newValues);
    this.props.doSnack(
      `Waarde ${
        this.state.newFeatureValue.key ? 'bijgewerkt' : 'toegevoegd'
      }. Vergeet deze feature niet op te slaan!`,
      'success'
    );
    this.setState({
      ...this.state,
      newFeatureValue: {},
    });
  };

  render() {
    const { featureDetail } = this.props;
    const { newFeatureValue } = this.state;

    return (
      <div>
        <div className="productfeaturedetail-root">
          <div className="productfeaturedetail-root__header">
            <button
              className="btn btn-primary"
              onClick={() => this.saveFeature()}
            >
              Sla gegevens op
            </button>
          </div>
          <div className="productfeaturedetail-root__form">
            <FormBlock
              title={`Details ${
                featureDetail.copyFromLanguage
                  ? `(Kopie van ${featureDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  <label>Naam *</label>
                  <input
                    id="title"
                    name="title"
                    value={featureDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateFeature('title', e.target.value)
                    }
                  />
                </CustomForm>
              </form>
            </FormBlock>
            {featureDetail._id && (
              <FormBlock title="Waarden">
                {(featureDetail.values && featureDetail.values.length && (
                  <table
                    border="1"
                    width="100%"
                    className="productfeaturedetail-root__valuetable"
                  >
                    <thead>
                      <tr>
                        <td>Waarde</td>
                        <td>&nbsp;</td>
                      </tr>
                    </thead>
                    <tbody>
                      {featureDetail.values.map((value, v) => (
                        <tr key={v}>
                          <td>{value.value}</td>
                          <td style={{ textAlign: 'right' }}>
                            <button
                              onClick={() => this.editFeatureValue(value)}
                              style={{ marginRight: 10 }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => this.removeFeatureValue(v)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )) ||
                  null}
                <CustomForm>
                  <label>Waarde *</label>
                  <input
                    id="valValue"
                    name="valValue"
                    value={newFeatureValue.value || ''}
                    onChange={(e) =>
                      this.updateNewFeatureValue('value', e.target.value)
                    }
                  />
                  {newFeatureValue.value && newFeatureValue.value.length && (
                    <button
                      className="btn btn-secondary"
                      onClick={(e) => this.saveFeatureValue(e)}
                    >
                      {newFeatureValue.key
                        ? 'Bewerk waarde'
                        : 'Sla nieuwe waarde op'}
                    </button>
                  )}
                </CustomForm>
              </FormBlock>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    featureDetail: state.productFeatures.featureDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListFeatures: () => {
      return dispatch(listFeatures());
    },
    doSaveFeature: () => {
      return dispatch(saveFeature());
    },
    doResetFeature: (feature) => {
      dispatch(resetFeature(feature));
    },
    doShowFeature: (id) => {
      return dispatch(showFeature(id));
    },
    doUpdateFeature: (field, val) => {
      dispatch(updateFeature(field, val));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFeatureDetail);
