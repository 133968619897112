export const UPDATE_CAMP = 'UPDATE_CAMP';
export const UPDATE_CAMPDATA = 'UPDATE_CAMPDATA';
export const UPDATE_CAMPV2 = 'UPDATE_CAMPV2';
export const SET_CAMP = 'SET_CAMP';
export const SET_CAMPDATA = 'SET_CAMPDATA';
export const SET_CAMPV2 = 'SET_CAMPV2';
export const SET_CAMPS = 'SET_CAMPS';
export const SET_CAMPSDATA = 'SET_CAMPSDATA';
export const SET_CAMPSV2 = 'SET_CAMPSV2';
export const LIST_CAMPS = 'LIST_CAMPS';
export const LIST_CAMPSDATA = 'LIST_CAMPS';
export const LIST_CAMPSV2 = 'LIST_CAMPSV2';
export const LOAD_CAMP = 'LOAD_CAMP';
export const LOAD_CAMPDATA = 'LOAD_CAMPDATA';
export const LOAD_CAMPV2 = 'LOAD_CAMPV2';
export const SAVE_CAMP = 'SAVE_CAMP';
export const SAVE_CAMPDATA = 'SAVE_CAMPDATA';
export const SAVE_CAMPV2 = 'SAVE_CAMPV2';
export const REMOVE_CAMPV2 = 'REMOVE_CAMPV2';
export const REMOVE_CAMP = 'REMOVE_CAMP';
export const REMOVE_CAMPDATA = 'REMOVE_CAMP';
export const SETACTIVE_CAMP = 'SETACTIVE_CAMP';
export const MAIL_WAITINGLIST = 'MAIL_WAITINGLIST';
