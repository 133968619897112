import {
  UPDATE_STAFF,
  SET_STAFF,
  SET_STAFFS,
  LIST_STAFFS,
  SAVE_STAFF,
  LOAD_STAFF,
} from '../actions/staff/types';

export const initialState = {
  isFetching: false,
  staff: [],
  staffDetail: {},
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STAFF:
      return {
        ...state,
        staffDetail: {
          ...state.staffDetail,
          [action.field]: action.value,
        },
      };
    case SET_STAFF:
      return {
        ...state,
        isFetching: false,
        staffDetail: action.staffDetail,
      };
    case SET_STAFFS:
      return {
        ...state,
        isFetching: false,
        staff: action.staff,
      };
    case SAVE_STAFF:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_STAFFS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_STAFF:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default staff;
