export const UPDATE_RENTAL = 'UPDATE_RENTAL';
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const UPDATE_RENTALCATEGORY = 'UPDATE_RENTALCATEGORY';
export const SET_RENTAL = 'SET_RENTAL';
export const SET_RESERVATION = 'SET_RESERVATION';
export const SET_RENTALCATEGORY = 'SET_RENTALCATEGORY';
export const SET_RENTALS = 'SET_RENTALS';
export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const LIST_RENTALS = 'LIST_RENTALS';
export const LIST_RESERVATIONS = 'LIST_RESERVATIONS';
export const LOAD_RENTAL = 'LOAD_RENTAL';
export const LOAD_RESERVATION = 'LOAD_RESERVATION';
export const LOAD_RENTALCATEGORY = 'LOAD_RENTALCATEGORY';
export const SAVE_RENTAL = 'SAVE_RENTAL';
export const SAVE_RESERVATION = 'SAVE_RESERVATION';
export const SAVE_RENTALCATEGORY = 'SAVE_RENTALCATEGORY';
export const REMOVE_RENTAL = 'REMOVE_RENTAL';
export const REMOVE_RESERVATION = 'REMOVE_RESERVATION';
export const REMOVE_RENTALCATEGORY = 'REMOVE_RENTALCATEGORY';
export const LIST_RENTAL_CATEGORIES = 'LIST_RENTAL_CATEGORIES';
export const SET_RENTAL_CATEGORIES = 'SET_RENTAL_CATEGORIES';
export const LIST_ACTIVE_RENTALS = 'LIST_ACTIVE_RENTALS';
export const SET_ACTIVE_RENTALS = 'SET_ACTIVE_RENTALS';
