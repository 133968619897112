import { Component } from "react";
import { connect } from "react-redux";
import StyledTable from "../../../components/StyledTable";
import { showSnack } from "../../../redux/actions/app";
import swal from "sweetalert";
import "./style.scss";
import {
  listFeatures,
  removeFeature,
} from "../../../redux/actions/productfeatures";

const fields = [
  {
    title: "Naam attribuut",
    prop: "title",
  },
];

const tableActions = ["edit", "delete"];

class ProductFeatures extends Component {
  state = {};

  componentDidMount = () => {
    this.props.doListFeatures();
  };

  editFeature = (feature) => {
    return this.props.history.push(`/campfeatures/detail/${feature._id}`);
  };

  deleteFeature = (feature) => {
    swal({
      title: "Item verwijderen",
      text: "Bent u zeker dat u het item wil verwijderen?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveFeature(feature._id)
          .then(() => {
            this.props.doSnack("Item verwijderd", "success");
            this.props.doListFeatures();
          })
          .catch(() => {
            this.props.doSnack("Probleem bij verwijderen");
          });
      }
    });
  };

  render() {
    const { productFeatures } = this.props;
    return (
      <div>
        <StyledTable
          title="Kamp attributen"
          fields={fields}
          bodyItems={productFeatures}
          actions={tableActions}
          addNew="Nieuw attribuut"
          addNewLink="/campfeatures/new"
          onEdit={this.editFeature}
          onDelete={this.deleteFeature}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productFeatures: state.productFeatures.productFeatures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListFeatures: () => {
      dispatch(listFeatures());
    },
    doRemoveFeature: (id) => {
      return dispatch(removeFeature(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFeatures);
