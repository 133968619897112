import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { signInUser } from '../../redux/actions/auth';
import CustomForm from '../../components/CustomForm';
import { AppName, logo, mainColor } from '../../config';
import { showSnack } from '../../redux/actions/app';

class SignIn extends Component {
  state = {
    email: undefined,
    password: undefined,
    website: undefined,
    error: undefined,
  };

  textChange = (field, val) => {
    this.setState({
      [field]: val,
    });
  };

  signin = (e) => {
    e.preventDefault();
    const { email, password, website } = this.state;
    if (website && website.length) return;
    if (!email || !email.length || !password || !password.length) {
      return this.setState({ error: 'Niet alle velden zijn correct ingevuld' });
    }
    this.props
      .doSignInUser(email, password)
      .then(() => {
        this.props.history.push('/users');
      })
      .catch((e) => {
        this.props.doSnack('Met deze gegevens kan je niet aanmelden', 'error');
      });
  };

  render() {
    const { email, password, website, error } = this.state;
    return (
      <div>
        <div className="signin-root" style={{ backgroundColor: mainColor }}>
          <h1>{AppName}</h1>
          <div className="signin-root__loginbox">
            <h2>Meld je aan</h2>
            <form>
              <CustomForm>
                <label htmlFor="email">E-mailadres</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email || ''}
                  onChange={(e) => this.textChange('email', e.target.value)}
                />
                <label htmlFor="password">Wachtwoord</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password || ''}
                  onChange={(e) => this.textChange('password', e.target.value)}
                />
                <div className="form__hidden">
                  <label htmlFor="website">Website</label>
                  <input
                    id="website"
                    name="website"
                    value={website || ''}
                    onChange={(e) => this.textChange('website', e.target.value)}
                  />
                </div>
                {error && <p className="form__error">{error}</p>}
                <button
                  className={`btn btn-primary ${
                    !email || !email.length || !password || !password.length
                      ? 'disabled'
                      : null
                  }`}
                  onClick={this.signin}
                >
                  Aanmelden
                </button>
              </CustomForm>
            </form>
          </div>
          <img
            src={logo}
            alt="logo"
            width="288"
            height="152"
            style={{ marginTop: 26 }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doSignInUser: (email, password) => {
      return dispatch(signInUser(email, password));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(null, mapDispatchToProps)(SignIn);
