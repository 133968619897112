import {
  LIST_AUDIENCE,
  LIST_NEWSLETTERS,
  REMOVE_NEWSLETTER,
  SET_NEWSLETTERS,
} from '../actions/newsletters/types';

const initialState = {
  isFetching: false,
  newsletters: [],
};

const newsletters = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NEWSLETTERS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        newsletters: action.newsletters || state.newsletters,
      };
    case REMOVE_NEWSLETTER:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case LIST_AUDIENCE:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_NEWSLETTERS:
      return {
        ...state,
        isFetching: false,
        newsletters: action.newsletters,
      };
    default:
      return state;
  }
};

export default newsletters;
