import fetchAxios from '../../middlewares/axios';
import {
  LIST_NEWSLETTERS,
  SET_NEWSLETTERS,
  REMOVE_NEWSLETTER,
  LIST_AUDIENCE,
} from './types';
import { ROOT_URL_PROTECTED } from '../../../config/axios';

export const listNewsletters = () => {
  return (dispatch) => {
    dispatch({ type: LIST_NEWSLETTERS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/newsletters/`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.newsletters
          ) {
            dispatch({
              type: SET_NEWSLETTERS,
              newsletters: response.data.newsletters,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_NEWSLETTERS });
          reject({ error: e });
        });
    });
  };
};

export const listAudience = () => {
  return (dispatch) => {
    dispatch({ type: LIST_AUDIENCE, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/newsletters/audience`,
        null,
        null,
        true
      )
        .then((response) => {
          dispatch({ type: LIST_AUDIENCE });
          if (response && response.data && response.data.success) {
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_AUDIENCE });
          reject({ error: e });
        });
    });
  };
};

export const removeNewsletter = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_NEWSLETTER, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/newsletters/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_NEWSLETTER,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_NEWSLETTER });
          reject({ error: e });
        });
    });
  };
};
