import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { createUTCDate } from '../../utils/date';
import {
  LIST_CAMPS,
  UPDATE_CAMP,
  SET_CAMP,
  SET_CAMPS,
  SAVE_CAMP,
  REMOVE_CAMP,
  SETACTIVE_CAMP,
  MAIL_WAITINGLIST,
  LIST_CAMPSV2,
  SET_CAMPV2,
  SET_CAMPSV2,
  UPDATE_CAMPV2,
  LIST_CAMPSDATA,
  REMOVE_CAMPDATA,
  UPDATE_CAMPDATA,
  SET_CAMPDATA,
  SET_CAMPSDATA,
  SAVE_CAMPDATA,
} from '../actions/camps/types';

const initialState = {
  isFetching: false,
  camps: [],
  campsv2: [],
  campsData: [],
  campDetail: {},
  campDetailv2: {},
  campdataDetail: {},
};

const camps = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CAMPS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        camps: action.camps || state.camps,
      };
    case LIST_CAMPSV2:
      return {
        ...state,
        isFetching: action.isFetching || false,
        campsv2: action.campsv2 || state.campsv2,
      };
    case LIST_CAMPSDATA:
      return {
        ...state,
        isFetching: action.isFetching || false,
        campsdata: action.campsdata || state.campsdata,
      };
    case REMOVE_CAMP:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_CAMPDATA:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case MAIL_WAITINGLIST:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_CAMP:
      return {
        ...state,
        campDetail: {
          ...state.campDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.campDetail.longDescription,
          dateFromUTC:
            action.field === 'dateFrom' &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campDetail.dateFromUTC,
          dateToUTC:
            action.field === 'dateTo' &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campDetail.dateToUTC,
        },
      };
    case UPDATE_CAMPDATA:
      return {
        ...state,
        campdataDetail: {
          ...state.campdataDetail,
          [action.field]: action.value,
          dateFromUTC:
            action.field === 'dateFrom' &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campdataDetail.dateFromUTC,
          dateToUTC:
            action.field === 'dateTo' &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campdataDetail.dateToUTC,
        },
      };
    case UPDATE_CAMPV2:
      return {
        ...state,
        campDetailv2: {
          ...state.campDetailv2,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.campDetailv2.longDescription,
        },
      };
    case SET_CAMP:
      return {
        ...state,
        isFetching: false,
        campDetail: action.campDetail,
      };
    case SET_CAMPDATA:
      return {
        ...state,
        isFetching: false,
        campdataDetail: action.campdataDetail,
      };
    case SET_CAMPV2:
      return {
        ...state,
        isFetching: false,
        campDetailv2: action.campDetailv2,
      };
    case SET_CAMPS:
      return {
        ...state,
        isFetching: false,
        camps: action.camps,
      };
    case SET_CAMPSDATA:
      return {
        ...state,
        isFetching: false,
        campsdata: action.campsdata,
      };
    case SET_CAMPSV2:
      return {
        ...state,
        isFetching: false,
        campsv2: action.campsv2,
      };
    case SAVE_CAMP:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_CAMPDATA:
      return { ...state, isFetching: action.isFetching || false };
    case SETACTIVE_CAMP:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default camps;
