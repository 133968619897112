import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import alasql from 'alasql';
import './style.scss';
import {
  listAudience,
  listNewsletters,
  removeNewsletter,
} from '../../../redux/actions/newsletters';

const fields = [
  {
    title: 'E-mailadres',
    prop: 'email',
  },
  {
    title: 'Voornaam',
    prop: 'firstname',
  },
  {
    title: 'Naam',
    prop: 'lastname',
  },
];

const tableActions = ['delete'];

class Newsletters extends Component {
  state = {};

  componentDidMount = () => {
    this.props.doListNewsletters();
  };

  deleteNewsletter = (newsletter) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveNewsletter(newsletter._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListNewsletters();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  downloadCSV = () => {
    this.props
      .doListAudience()
      .then((res) => {
        if (res.data && res.data.audience && res.data.audience.length) {
          const itemsSorted = res.data.audience.sort((a, b) => {
            if (a.email < b.email) {
              return -1;
            } else if (a.email > b.email) {
              return 1;
            } else {
              return 0;
            }
          });
          setTimeout(() => {
            alasql(
              'SELECT * INTO XLSX("' +
                'audience' +
                '_' +
                Date.now() +
                '.xlsx",{headers:true}) FROM ?',
              [itemsSorted]
            );
            swal('Download', 'Download afgerond', 'success');
          }, 1000);
        }
      })
      .catch(() => {
        this.props.doSnack('Probleem bij ophalen lijst van e-mailadressen.');
      });
  };

  render() {
    const { newsletters } = this.props;
    return (
      <div>
        <div style={{ width: '100%', padding: 16 }}>
          <button className="btn btn-info" onClick={() => this.downloadCSV()}>
            Download Excel voor Mailchimp
          </button>
        </div>
        <StyledTable
          title="Inschrijvingen Nieuwsbrief"
          fields={fields}
          bodyItems={newsletters}
          actions={tableActions}
          onDelete={this.deleteNewsletter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsletters: state.newsletters.newsletters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListNewsletters: () => {
      dispatch(listNewsletters());
    },
    doRemoveNewsletter: (id) => {
      return dispatch(removeNewsletter(id));
    },
    doListAudience: () => {
      return dispatch(listAudience());
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletters);
