import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from './components/Loader';
import Main from './pages/Main';
import SignIn from './pages/Signin';
import PrivateRoute from './PrivateRoute';
import Snack from './components/SnackBar/Snack';

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/signin" render={(props) => <SignIn {...props} />} />
          <PrivateRoute path="/" component={Main} />
        </Switch>
        <Snack />
      </Suspense>
    </div>
  );
}

export default App;
